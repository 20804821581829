// Fonts
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Lato:wght@400;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '_type';
@import '_header';
@import '_footer';
@import '_buttons';
@import '_hero';
@import '_general';
@import '_utilities';
@import '_misc';
@import '_sidebar';

@import '_hero';
@import '_touchdown';
