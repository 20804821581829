// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Lato', sans-serif;
$headings-font-family: 'Anton', sans-serif;
$heading-font-family: 'Anton', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue_light: #024EA1;
$blue: #013A74;
$blue_dark: #001A38;
$blue_bright: #56AAFF;

$light: #F0F4F7;

$lime: #C0D72E;

$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$text-muted: #727272;

//////

$primary: $lime;
$secondary: $blue;

$theme-colors: (
  "blue-bright": $blue_bright,
  "blue-medium": $blue,
  "blue-dark": $blue_dark,
  "blue-light": $blue_light,
  "lime": $lime,
  "light": $light
);

$btn-padding-y: .375rem;
$btn-padding-x: 2.375rem;
$btn-font-size: 18px;

$border-radius-lg: 1rem;

$body-bg: white;
