footer {

    margin-top: auto;

    .links {
        font-size: 17px;
    }

    .social {
        div {
            width: 35px;
            height: 35px;

            @include media-breakpoint-down(md) {
                width: 55px;
                height: 55px;

                i.fa {
                    font-size: 200%;
                }
            }
        }
    }

    .teams {
        a {
            &:hover {
                text-decoration: none;
            }
        }

        img {
            height: 31px;
            width: 31px;
        }
    }


    .social-facebook {
        -webkit-text-size-adjust: 100%;
        font-family: Lato, sans-serif;
        line-height: 1.5rem;
        --bs-gutter-x: 30px;
        --bs-gutter-y: 0;
        -webkit-box-direction: normal;
        list-style: none;
        box-sizing: inherit;
        border: 1px solid #0094ce;
        border-radius: 8px;
        color: #fff;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 1.125rem;
        font-weight: 700;
        position: relative;
        text-align: center;
        transition: all .3s ease;
        text-decoration: none;
        height: 40px;
        width: 213px;
        padding: 8px 0;
        background-color: #0094ce;
        border-color: #0094ce;
    }

    .social-youtube {
        -webkit-text-size-adjust: 100%;
        font-family: Lato, sans-serif;
        line-height: 1.5rem;
        --bs-gutter-x: 30px;
        --bs-gutter-y: 0;
        -webkit-box-direction: normal;
        list-style: none;
        box-sizing: inherit;
        border: 1px solid #0094ce;
        border-radius: 8px;
        color: #fff;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 1.125rem;
        font-weight: 700;
        position: relative;
        text-align: center;
        transition: all .3s ease;
        text-decoration: none;
        height: 40px;
        width: 213px;
        padding: 8px 0;
        background-color: #e62017;
        border-color: #e62017;
    }

    .footer-column-social-item {
        -webkit-text-size-adjust: 100%;
        font-family: Lato, sans-serif;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #000;
        --bs-gutter-x: 30px;
        --bs-gutter-y: 0;
        -webkit-box-direction: normal;
        list-style: none;
        box-sizing: inherit;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        padding: 0;
        width: 100%;
    }

    .footer-column-social {
        -webkit-text-size-adjust: 100%;
        font-family: Lato, sans-serif;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #000;
        --bs-gutter-x: 30px;
        --bs-gutter-y: 0;
        box-sizing: inherit;
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        list-style: none;
        margin: 0;
        -webkit-box-ordinal-group: 1;
        order: 0;
        padding: 0 0 30px;
        -webkit-box-pack: start;
        justify-content: flex-start;
        padding-bottom: 28px;
    }

}
