.btn {
	font-family: $headings-font-family;
	letter-spacing: 0.2px;
	border-radius: 0;

	&.btn-primary {
		color: $blue;
	}

	&.btn-secondary {
		color: $blue_bright;
	}
}