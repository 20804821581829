header {
	.title {
		font-size: 30px;
	}

	img.brand {
		height: 70px;
		width: auto;
	}

	.nav-link {
		font-size: 17px;
	}

	.dropdown-menu {
		border-radius: 0;

		.dropdown-item {
			text-align: right;
		}
	}

	background-color: #003A74;

}

.navbar-collapse.mobile {
	background: $blue;
	border-bottom: 3px solid $blue_bright;

	@include media-breakpoint-down(md) {
		font-size: 22px;
		font-weight: 400 !important;
	}
}