.flex-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
}

.invalid-feedback {
    display: block !important;
}

#touchdown-table {
    thead {
        th {
            background-color: #0094ce;
            height: 88px;
            width: 120px;
        }
    }

    #school-column {
        width: 269px;
    }

    th {
        i {
            color: #C0D72E;
        }
    }

    tbody {
        td {
            i {
                color: #C0D72E;
            }
        }
    }
}
