.touchdown-steps {
	background-repeat: repeat;
	background-image: url('/images/pattern_touchdown.png');
	background-size: 35%;

	i.fa {
		color: #747474;
		font-size: 44px;
	}

	svg {
		fill: #747474;
		width: 55px;
		height: 55px;
	}

	.completed {
		i.fa {
			color: $lime;
		}

		svg {
			fill: $lime;
		}
	}

	.step {
		position: relative;

		.inner {
			min-width: 135px;

			@include media-breakpoint-down(md) {
				min-width: auto;
				width: 100%;
			}
		}

		@include media-breakpoint-down(md) {
			width: 100%;
		}

		.active {
			position: absolute;
			width: 100%;
		    display: flex;
		    justify-content: center;
		    bottom: -32px;

		    @include media-breakpoint-down(md) {
		    	bottom: -26px;
		    }
			
			.triangle {
				position: relative;
				width: 0px;
				height: 0px;
				border-left: 30px solid transparent;
				border-right: 30px solid transparent;
				border-top: 40px solid $lime;
				margin-top: -0.5rem;

				@include media-breakpoint-down(md) {
					border-left: 20px solid transparent;
					border-right: 20px solid transparent;
					border-top: 30px solid $lime;
				}
			}
		}
	}
}