.font-heading {
	font-family: $headings-font-family;
}

.text-underline {
	text-decoration: underline;
}

p {
	line-height: 1.9em;
}

main {
	a {
		&:not(.btn) {
			color: $blue_light;
			text-decoration: underline;
			font-weight: bold;
		}
	}
}

.bg-highlight {
	background: #D8DFE5;
}

.text-16 {
    font-size: 16px;
}

.text-14 {
    font-size: 14px;
}
