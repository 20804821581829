.hero {
	.image {
		//clip-path: polygon(13% 0, 100% 0, 100% 100%, 13% 100%, 0 50%);
		height: 343px;
		// background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	background-color: #4dA5F9;

	h1 {
		color: #003A74;
	}
}
