.sidebar {
	.dropdown-toggle {
		width: 100%;
		color: $secondary;
		font-family: $font-family-sans-serif;
		font-weight: 300;
		display: flex;
		justify-content: space-between;
		padding-left: 1rem;
		padding-right: 1rem;

		&:focus, &:hover {
			box-shadow: none;
			text-decoration: none;
		}

		&::after {
			font-family: 'FontAwesome';
			content: '\f107';
			font-weight: 300;
			border: none;
			color: $dark;
		}
	}

	.dropdown-menu {
		width: 97%;
		font-size: 1.125rem;

		a.dropdown-item {
			font-weight: 300;
			text-decoration: none;
			padding-left: 12px;
		}
	}
}